/* @font-face {
  font-family: extraBold;
  src: url('./assets/fonts/Roboto-Black.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Black.ttf') format('ttf');
}

@font-face {
  font-family: light;
  src: url('./assets/fonts/Roboto-Light.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Light.ttf') format('ttf');
}

@font-face {
  font-family: regular;
  src: url('./assets/fonts/Roboto-Regular.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: bold;
  src: url('./assets/fonts/Roboto-Bold.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Bold.ttf') format('ttf');
}
@font-face {
  font-family: thin;
  src: url('./assets/fonts/Roboto-Thin.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Thin.ttf') format('ttf');
}
@font-face {
  font-family: medium;
  src: url('./assets/fonts/Roboto-Medium.ttf') format('ttf'),
       url('./assets/fonts/Roboto-Medium.ttf') format('ttf');
} */

/* [data-darkreader-inline-fill] {
  fill: currentColor !important;
} */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #fff;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


a {
  text-decoration: none;
}

/* img {
  width: 100%;
} */

.navbar-light .navbar-nav .nav-link {
  color: rgba(49, 65, 85, 1) !important;
}

.MuiCardActions-root {
  justify-content: center;
  margin-top: 20px;
}

.preformated {
  white-space: pre-line;
  color: #3D3D3D;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
}

.bullet {
  display: inline-block;
  margin: 0 5px 0 0;
  line-height: 25px;
  vertical-align: middle;
}

.cookiesText {
  display: inline-block;
  padding: 0 5px 0 25px;
  line-height: 25px;
  vertical-align: middle;
}

.carousel-indicators ol {
  background-color: red;
}

.slick-costum {
  width: 100%;
}

.passwordLabel {
  color: #5C5C5C !important;
  padding-right: 20px !important;
}

.passwordLabelDisabled {
  color: #5C5C5C !important;

  padding-right: 20px !important;
  opacity: 0.5;
}

.cookies-link {
  color: #EE4352;

}

.cookies-link:hover {
  color: #90205D;

}

.cookiesButtonWrapper {
  display: flex;
  flex: 1;
}

.headerH1 {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.5;
}


@media (min-width:960px) and (max-width:1279px) {
  .headerH1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.5;
  }
}

@media (max-width:959px) {
  .headerH1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
  }
}

/* first div img */

@keyframes slideLeftImg {
  from {
    transform: translateX(800px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

#home3 {
  animation-name: slideLeftImg;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

#divOffer {
  animation-name: fadeInAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

/* table fades in */

@keyframes modalFadeIn {
  from {transform: translateX(-50%);opacity: 0;}
  to {transform: translateX(0);opacity: 1;}
}

#table {
  animation-name: modalFadeIn;
  animation-duration: 1s;
}

/* #faqImage {
  background-image: url('https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/faq-background.svg');
  background-repeat: no-repeat;
} */

#home1 {
  /* background-image: url('https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/Bg-1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  background: linear-gradient(136deg, #90205D 0%, #EE4352 104.86%);
}

/* first div writing */

@keyframes slideLeft {
  from {
    transform: translateX(-800px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

#home2 {
  animation-name: slideLeft;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

/* Nookal, xero logos */

#icon:hover {
  transform: translateY(-7px);
}

#icon {
transition: transform 0.3s ease-in;
}

#layout {
  background-image: url('https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/header-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#footer {
  background-image: url('../src/assets/svg/Group\ 9294.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1100px;
  width: 100%;
  margin-inline: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#snaps::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#snaps {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

#snaps {
  scroll-behavior: smooth;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 15;
}

#snaps > * {
  scroll-snap-align: start;
}

#left {
  cursor: pointer;
  position: absolute;
  right: 96%;
  transform: translateY(-50%);
}

#right {
  cursor: pointer;
  position: absolute;
  left: 96%;
  transform: translateY(-50%);
}

#TESTIMONIAL .slick-list {
  -webkit-transition: height 250ms linear;
  overflow: hidden;
}

#price .slick-list {
  -webkit-transition: height 250ms linear;
  overflow: visible;
  overflow-x: clip;
}

#PLANS2 .slick-list {
  -webkit-transition: height 250ms linear;
  overflow: visible;
  overflow-x: clip;
}

.slick-costum {
  border: 1px solid #CECECE;
  border-radius: 10px;
}

.slick-home {
  width: 100%;
}

#TESTIMONIALS .slick-dots li button:before {
  color: #EE4352 !important;
  font-size: 12px !important;
  line-height: 50px !important;
}

@media (max-width:480px) {
  .slick-costum {
    width: 90%;
    margin: auto;
    border: 1px solid #CECECE;
    border-radius: 10px;
  }
  .slick-home {
    width: 90%;

  }
}

@media (max-width:768px) {
  .cookiesButtonWrapper {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

.slick-prev::before {
  display: none;

}

.slick-next::before {
  display: none;
}

.slick-track {
  margin:auto;
}

@media (max-width:1280px) {
  .slick-costum {
    width: 90%;
    margin: auto;
    border: 1px solid #CECECE;
    border-radius: 10px;
  }
  .slick-home {
    width: 90%;
  }
}

/* pricing animation */
.page-enter {
  opacity: 0;
  transform: translateX(800px);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 900ms linear, transform 900ms;
}


/* second */

.second-enter {
  opacity: 0;
  transform: translateX(-800px);
}

.second-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 900ms linear, transform 900ms;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* scroll up btn */

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 20;
}

.icon-style {
  background-color: #90205D;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551B54;
  border: 2px solid #90205D;
}

@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}

@media (max-width:600px) {
  .icon-position {
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 20;
  }
}

.scrollContainer {
  display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 100vw;
	overflow: hidden;
	background: #fff;
}

.scroll 
{
	max-width: 1300px;
  width: 100%;
	display: flex;
	overflow: hidden;
  -webkit-mask-image: linear-gradient(94deg,transparent, #fff 20% , #fff 80%,transparent);
}

.scroll div 
{
	text-transform: uppercase;
	letter-spacing: 0.2em;
	white-space: nowrap;
	animation: animate 60s linear infinite;
	animation-delay: calc(60s * -1);
}
.scroll div:nth-child(2)
{
	animation: animate2 60s linear infinite;
	animation-delay: calc(60s / -2);
}

.scroll div img 
{
	display:inline-flex;
  margin-inline: 15px;
	margin-bottom: 10px;
	/* background: #333; */
	/* color: #fff; */
	/* padding: 5px 10px; */
	/* border-radius: 5px; */
	transition: 0.5s;
  height: 74px;
  width: 120px; 
  object-fit: contain; 
}
.scroll div span:hover 
{
	/* color: #fff;
	transition: 
  0s; */
}

.scrollContainer2 {
  display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 100vw;
	overflow: hidden;
	background: #fff;
  min-height: 500px;
}

.scroll2 
{
	max-width: 1300px;
  width: 100%;
	display: flex;
	overflow: hidden;
  min-height: 500px;
  -webkit-mask-image: linear-gradient(94deg,transparent, #fff 20% , #fff 80%,transparent);
}

.scroll2 div 
{
	white-space: nowrap;
	animation: animate 200s linear infinite;
	animation-delay: calc(200s * -1);
}
.scroll2 div:nth-child(2)
{
	animation: animate2 200s linear infinite;
	animation-delay: calc(200s / -2);
}

.scroll2 div article 
{
  margin-inline: 45px;
	margin-bottom: 10px;
	/* background: #333; */
	/* color: #fff; */
	/* padding: 5px 10px; */
	/* border-radius: 5px; */
	transition: 0.5s;
 
}

@keyframes animate 
{
	0% 
	{
		transform: translateX(100%);
	}
	100% 
	{
		transform: translateX(-100%);
	}
}

@keyframes animate2 
{
	0% 
	{
		transform: translateX(0);
	}
	100% 
	{
		transform: translateX(-200%);
	}
}


.scroll:nth-child(5) div 
{
	display: flex;
}
.scroll img 
{
	max-width: 100px;
	/* scale: 0.8;
	filter: grayscale(1); */
}
.scroll img:hover 
{
	/* filter: grayscale(0); */
} 
span.clr 
{
	background: #ff376f !important;
	color: #fff !important;
}

.wrapper {
  max-width: 1300px;
  width: 100%;
	display: flex;
	overflow: hidden;
  /* padding-block: 30px; */
  -webkit-mask-image: linear-gradient(94deg,transparent, #fff 20% , #fff 80%,transparent);
  height: 280px;
  position: relative;
  overflow: hidden;
  margin-inline: auto;
}

@media (min-width:1921px) {
  .scroll {
    max-width: 1500px;
  }
  #footer {
    max-width: 1300px;
  }
  .cards {
    padding: 0 0 0 200px !important;
  }
  .wrapper {
    max-width: 1500px;
  }
}

@media (max-width:1800px) {
  .cards {
    padding: 0 0 0 12% !important;
  }
}

@media (max-width:1600px) {
  .cards {
    padding: 0 0 0 8% !important;
  }
}

@media (max-width:1440px) {
 .scroll {
  max-width: 1000px;
 }
 #footer {
  max-width: 1000px;
}
.cards {
  padding: 0 0 0 200px !important;
}
.wrapper {
  max-width: 1000px;
}
}

@media (max-width:1300px) {
  .cards {
    padding: 0 0 0 8% !important;
  }
}
/* 
@media (max-width:1100px) {
  .cards {
    padding: 0 0 0 4% !important;
  }
} */

@media (max-width:1100px) {
  .scroll {
   max-width: 900px;
  }
  #footer {
   max-width: 900px;
 }
 .cards {
  padding: 0 0 0 8% !important;
}
.wrapper {
  max-width: 900px;
}
}

@media (max-width:960px) {
  .scroll {
   max-width: 700px;
  }
  #footer {
   max-width: 700px;
 }
 .cards {
  padding: 0 0 0 4% !important;
}
.wrapper {
  max-width: 700px;
}
}

.itemLeft,
.itemRight {
  background: #F8F8F8;
    border-radius: 5px;
    border: 1px solid #293E57;
    width: 553px;
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    padding-top: 16px;
    padding-bottom: 23px;
    gap: 8px;
  position: absolute;

}

.itemLeft {
  left: max(calc(553px * 8), 100%);
  animation-name: scrollLeft;
  animation-timing-function: linear;
  animation-duration: 100s;
  animation-iteration-count: infinite;
}

.itemRight {
    left: max(calc(553px * 8), 100%);
    animation-name: scrollLeft;
    animation-timing-function: linear;
    animation-duration: 120s;
    animation-iteration-count: infinite;
}


@media (max-width:760px) {
  .scroll {
   max-width: 600px;
  }
  #footer {
   max-width: 95%;
 }
 .cards {
  padding: 0 0 0 4% !important;
}
.wrapper {
  max-width: 600px;
 
}
.itemLeft {
  left: max(calc(553px * 8), 100%);
  animation-name: scrollLeft;
  animation-timing-function: linear;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  width: 300px;
}

.itemRight {
    left: max(calc(553px * 8), 100%);
    animation-name: scrollLeft;
    animation-timing-function: linear;
    animation-duration: 150s;
    animation-iteration-count: infinite;
    width: 300px;
}
}

 @media (max-width:600px) {
  .container {
  padding-inline: 0 !important;
  }
  .scroll {
    max-width: 400px;
   }
   .cards {
    padding: 0 0 0 2% !important;
  }
  .wrapper {
    max-width: 400px;
  }
 }

 @media (max-width:430px) {
   .cards {
    padding: 0 0 0 2% !important;
  }
  .scroll {
    max-width: 350px;
   }
   .wrapper {
    max-width: 350px;
  }
 }

#buttonAiSection span {
  display: inline-block;
  transition: 0.3s ease-in;
}

#buttonAiSection:hover span {
  transform: translateX(50%);
}

@keyframes gradientAnimation {
  0% {
    background: linear-gradient(90deg, #90205D 0%, rgba(238, 67, 82, 0.97) 100%);
  }
  50% {
    background: linear-gradient(90deg, rgba(238, 67, 82, 0.97) 0%, #90205D 100%);
  }
  100% {
    background: linear-gradient(90deg, #90205D 0%, rgba(238, 67, 82, 0.97) 100%);
  }
}

#aiDiv {
  background: linear-gradient(90deg, #90205D 0%, rgba(238, 67, 82, 0.97) 100%);
  background-size: 200% 200%;

    -webkit-animation: Animation 4s ease infinite;
    -moz-animation: Animation 4s ease infinite;
    animation: Animation 4s ease infinite;
}

@-webkit-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@keyframes Animation { 
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}

/* #whatDPoffers span::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
} */



.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-inline: 0px !important;
  max-width: 100% !important;
  background: #F8F8F8;
}

.space-holder {
  position: relative;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 30px;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.horizontal {
  position: absolute;
  height: 100%;
  will-change: transform;
}

.cards {
  position: relative;
  height: 100%;
  padding: 0 0 0 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.sample-card {
  position: relative;
  height: 400px;
  width: 450px;
  background-color: #111f30;
  /* margin-right: 60px; */
  flex-shrink: 0;
}

@keyframes scrollLeft {
  to {
    left: -300px;
  }
}

@keyframes scrollRight {
  to {
    right: -300px;
  }
}



.item1 {
  animation-delay: calc(100s / 8 * (8 - 1) * -1);
}

.item2 {
  animation-delay: calc(100s / 8 * (8 - 2) * -1);
}

.item3 {
  animation-delay: calc(100s / 8 * (8 - 3) * -1);
}

.item4 {
  animation-delay: calc(100s / 8 * (8 - 4) * -1);
}

.item5 {
  animation-delay: calc(100s / 8 * (8 - 5) * -1);
}

.item6 {
  animation-delay: calc(100s / 8 * (8 - 6) * -1);
}

.item7 {
  animation-delay: calc(100s / 8 * (8 - 7) * -1);
}

.item8 {
  animation-delay: calc(100s / 8 * (8 - 8) * -1);
}

.item9 {
  animation-delay: calc(120s / 8 * (8 - 1) * -1);
}

.item10 {
  animation-delay: calc(120s / 8 * (8 - 2) * -1);
}

.item11 {
  animation-delay: calc(120s / 8 * (8 - 3) * -1);
}

.item12 {
  animation-delay: calc(120s / 8 * (8 - 4) * -1);
}

.item13 {
  animation-delay: calc(120s / 8 * (8 - 5) * -1);
}

.item14 {
  animation-delay: calc(120s / 8 * (8 - 6) * -1);
}

.item15 {
  animation-delay: calc(120s / 8 * (8 - 7) * -1);
}

.item16 {
  animation-delay: calc(120s / 8 * (8 - 8) * -1);
}